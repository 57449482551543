import RizkService from '@Services/rizkService';
import ReactLobby from './components/reactLobby';
// import { webSocket } from '@Services/websocket'
import { RGTriggers } from './components/InboxNew/triggers';
import CampaignGame from './components/CampaignGame/index';
import SeasonCampaign from './components/valentinesCampaign';
import { Race } from './components/RaceNew/RaceNew';
import { GameSessionHelper } from '@Utils/gameSessionHelper';
import { showClock, lastLogin } from '@Utils';
import { GameStatsCounter } from '@Utils/gameStatsCounter';
import { loadLiveChat, loadFrameChat, loadHelpCenter } from '@Utils/livechat';
import PassField from './components/passfield';
import { BehaviorSubject } from 'rxjs';
import MenuItemLinks from './components/menuItemLinks';
import { HostedFields, FieldTypes } from 'hosted-fields-sdk';

const rizkApp = require('@Helpers/rizk-app');

var passfield = new PassField();
var menuItemLinks = new MenuItemLinks();

/**
 *
 * @param {{...any}} props
 * @deprecated Backwards Support for FrontendV1
 */
export function init(props = {}) {
  const { entrypoint } = props;
  window.Rizk = window.Rizk || {};
  window.Rizk.isFeatureActive = rizkApp.isFeatureActive;
  window.Rizk.isFeatureOn = rizkApp.isFeatureOn;
  window.loadLiveChat = loadLiveChat;
  window.loadFrameChat = loadFrameChat;
  window.loadHelpCenter = loadHelpCenter;
  window.checkPassword = passfield.checkPassword;
  window.checkPasswordEmail = passfield.checkPasswordEmail;
  window.togglePassword = passfield.togglePassword;
  window.Rizk.lazyObservable = new BehaviorSubject([]);
  window.customElements.define('campaign-game', CampaignGame);
  //window.customElements.define("season-campaign", ValentinesCampaign);

  window.Rizk.setupCreditCardFields = () => {
    const fields = [
      {
        type: FieldTypes.CREDITCARD_NUMBER,
        autocomplete: 'cc-number',
        id: 'cc-number',
        key: 'creditcard.main.number',
        name: 'cardnumber',
        noAttributeValueFormatting: true,
        required: true,
        requiredNewPayment: true,
        requiredRepeatPayment: false,
        showIcon: true,
        value: undefined,
        visible: true,
        visibleOnNewPayment: true,
        visibleOnRepeatPayment: false,
      },
    ];

    let cardInputTimer;

    const onLoadCallbackWithPolling = () => {
      return () => {
        console.log('Hosted fields loaded successfully');

        startPolling();

        const inputField = document.getElementById('cc-number');
        if (inputField) {
          inputField.addEventListener('input', startPolling);
          inputField.addEventListener('change', startPolling);

          inputField.addEventListener('focus', () => {
            setTimeout(startPolling, 500);
          });

          inputField.addEventListener('mouseenter', startPolling);
          inputField.addEventListener('mouseleave', stopPolling);

          inputField.addEventListener('touchstart', startPolling);
          inputField.addEventListener('touchend', () => {
            setTimeout(stopPolling, 3000);
          });

          const observer = new MutationObserver(() => {
            startPolling();
          });

          observer.observe(inputField, { attributes: true, attributeFilter: ['value'] });
        }
      };
    };

    function startPolling() {
      cardInputTimer = setInterval(() => {
        HostedFields.get();
      }, 1000);
    }

    function stopPolling() {
      clearInterval(cardInputTimer);
    }

    HostedFields.setup({
      merchantId: RizkConfig.merchantId,
      hostedfieldsurl: RizkConfig.hostedFieldsUrl,
      fields: fields,
      renderMode: 'single',
      styles: `
        .creditcardNumber-container,
        .input-container,
        .control,
        .hosted-field-container,
        div[class*="creditcardNumber-container"] {
          width: 100% !important;
          display: block !important;
          margin: 0 !important;
          padding: 0 !important;
          box-sizing: border-box !important;
        }

        .creditcardNumber-container.input-container.empty {
          display: block !important;
          width: 100% !important;
        }

        label[for="cc-number"],
          #creditcardNumberLabel {
          font-weight: normal !important;
          display: block !important;
          margin-bottom: 5px !important;
        }

        .hosted-input-container.single-iframe-input-container {
          width: 100% !important;
          padding: 0 !important;
          margin: 0 !important;
          box-sizing: border-box !important;
        }

        body {
          padding: 0;
          margin: 0;
          width: 100% !important;
          height: 100% !important;
          outline: none;
          background: transparent;
        }

        input {
          padding:0; margin:0;
        }

        :-moz-submit-invalid,
        :-moz-ui-invalid,
        input:invalid {
          box-shadow: none;
        }

        .creditcard-number-input {
          width: 100%;
          margin: 4px auto 16px;
          color: #000;
          border: 1px solid #D1D1D1;
          outline: none;
          line-height: 20px;
          font-weight: 700;
          box-sizing: border-box;
        }

        .hosted-input-container {
          position: relative;
          vertical-align: top;
          padding-top: 2px;
          padding-left: 2px;
        }

        .hosted-input-container input {
          display: inline-block;
          background: #ffffff;
          margin: 0;
          padding: 10px 35px 10px 15px;
          font-size: 16px;
          border: 1px solid #cfcfcf;
          border-radius: 5px;
        }

        .hosted-input-container input:focus {
          border: 1px solid #009a39;
        }

        .hosted-input-container input:focus > label {
          color: #009a39;
        }

        .hosted-input-container input.valid {
          border-color: #009a39;
        }

        #hosted-field-single-iframe {
          width: 100% !important;
          max-width: 100% !important
          height: 100%;
          border: none;
          display: block;
          min-width: 100% !important;
          box-sizing: border-box;
        }

        .hosted-field-container {
          width: 100% !important;
          max-width: 100% !important
          height: 100%;
          border: none;
          display: block;
          min-width: 100% !important;
          box-sizing: border-box;
        }

        #hosted-field-container-single-iframe iframe,
        #hosted-field-container-single-iframe #hosted-field-single-iframe {
          width: 100% !important;
          max-width: 100% !important;
          height: 100% !important;
          max-height: 100% !important;
          border: none !important;
          display: block !important;
          min-width: 100% !important;
          box-sizing: border-box !important;
          margin: 0 !important;
          padding: 0 !important;
        }
          `,
      callback: () => {
        return (formData) => {
          if (formData.errors) {
            return;
          }

          if (formData && formData.encCreditcardNumber) {
            const encField = document.getElementById('enc-cc');
            if (encField) {
              encField.value = formData.encCreditcardNumber;
            }
          }
          return formData;
        };
      },
      onLoadCallback: onLoadCallbackWithPolling,
      autoFocusNext: true,
      el: '#cc-field-container',
    });
  };

  window.Rizk.getHostedFields = () => {
    return HostedFields.get();
  };

  window.linksActive = menuItemLinks.linksActive;

  window.Rizk.cache = {
    get: rizkApp.rizkCacheGet,
    set: rizkApp.rizkCacheSet,
    expire: rizkApp.rizkCacheExpire,
  };

  if (rizkApp.isFeatureOn('react-lobby', RizkConfig)) {
    loadReactLobby();
  }

  window.Rizk.Race = new Race();
  window.addEventListener('load', function () {
    const locale = RizkConfig.locale || 'en';

    this.window.Rizk = this.window.Rizk || {};
    this.window.Rizk.rizkService = new RizkService(locale);
    this.window.Rizk.gameSessionHelper = new GameSessionHelper();
    this.window.Rizk.rgTriggers = new RGTriggers();
    this.window.Rizk.gameStatsCounters = {};
    this.window.Rizk.GameStatsCounter = GameStatsCounter;
    // this.window.Rizk.Socket = webSocket(window.location.host, RizkConfig)
    // Commented out because of the new react Lobby
    // setTimeout(function(){
    //   window.Rizk.SeasonCampaign = new SeasonCampaign()
    // }, 500);

    menuItemLinks.linksActive();

    if (entrypoint !== 'frameview') {
      showClock(document.getElementById('clock'));
      lastLogin();
    }
    // every 30 seconds expire the cache
    rizkApp.rizkCacheExpire();
    setInterval(function () {
      rizkApp.rizkCacheExpire();
    }, 30 * 1000);
  });
}

async function loadReactLobby() {
  const reactLobby = new ReactLobby();
  reactLobby.init();
  await import('@zecure-react-components/lobby/dist/NewLobby');
}
